import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

require('./BonusRightsCard.scss');


const mapDate = (date) => {
  const dateObject = new Date(date);
  return `${(`0${dateObject.getDate()}`).substr(-2)}-${(`0${dateObject.getMonth() + 1}`).substr(-2)}-${dateObject.getFullYear()}`; // eslint-disable-line max-len
};

const BonusRightsCard = ({ bonusrights }) => (
  <Col
    xs={12}
    md={6}
    className="bonusrightscard"

  >
    <Row className={
      ClassNames('bonusrightscard__wrapper', { 'bonusrightscard--used': bonusrights.used })}
    >
      <Col xs={12}>

        <h1 className="bonusrightscard__title">
          {bonusrights.title}
        </h1>
        <span className="bonusrightscard__expiredate">
          {bonusrights.expired ? 'Vervallen op:' : 'Vervalt op:'}
          {' '}
          <strong>{mapDate(bonusrights.expires)}</strong>
        </span>
      </Col>
      <Col
        xs={12}
        className={ClassNames('bonusrightscard__status', {
          'bonusrightscard__status--used': bonusrights.used,
          'bonusrightscard__status--expired': bonusrights.expired,
        })}
      >
        {!bonusrights.used && (bonusrights.expired ? 'vervallen' : 'ongebruikt')}
        {bonusrights.used && (
          <>
            <strong>gebruikt op:</strong>
            {' '}
            {bonusrights.used_when}
          </>
        )}
      </Col>
    </Row>
  </Col>);

BonusRightsCard.propTypes = {
  bonusrights: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BonusRightsCard;
