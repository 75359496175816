import React, { Component } from 'react';
import { Row, Grid } from 'react-bootstrap';
// import { NavLink } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import SubscriptionCard from '../SubscriptionCard/SubscriptionCard';
import RenewSubscriptionCard from '../RenewSubscriptionCard/RenewSubscriptionCard';
import BonusRightsCard from '../BonusRightsCard/BonusRightsCard';

require('./SubscriptionOverview.scss');


class SubscriptionOverview extends Component {
  constructor() {
    super();
    this.state = { loading: true, errorMsg: '', subscriptions: {} }; // eslint-disable-line
  }

  componentDidMount() {
    this.fetchSubscriptions();
  }

  fetchSubscriptions = async () => {
    this.setState({ loading: true });

    const data = await fetch(`${process.env.CONF_API_URL}/api/users/me/subscriptions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((res) => { if (res.status === 401) { window.location = '/auth/login'; } return res.json(); });
    if (!data.success) {
      this.setState({ loading: false, errorMsg: data.error || 'fout bij het ophalen van abonnementen' });
    } else {
      this.setState({ loading: false, subscriptions: data.data });
    }
  }

  render() {
    const { loading, subscriptions, errorMsg } = this.state; // eslint-disable-line

    return (
      <section className="wizardStep wizardStep--withMenu">
        <Grid className="wizardStep__section">
          <div className="wizardStep__section__wrapper">
            {loading && <Loader className="dataLoader" size={36} />}

            {!loading && errorMsg && (
              <p>
                {`<strong>Fout bij het ophalen van abonnementen:</strong> ${errorMsg}`}
              </p>
            )}

            {!loading && subscriptions && subscriptions.extension_options && (
              <>
                {!subscriptions.current
                  && (<h1 className="h1--renewable">Je hebt geen abonnement, kies één van onze abonnementen</h1>)}

                {subscriptions.current
                  && (<h1 className="h1--renewable">Je abonnement verloopt bijna, verleng hier je abonnement!</h1>)}

                {subscriptions.extension_options.personal_offers.length > 0 && (
                  <h2>Persoonlijk verlengaanbod</h2>
                )}
                <div>
                  {subscriptions.extension_options.personal_offers.map(
                    (offer => (<RenewSubscriptionCard offer={offer} />)),
                  )}
                </div>
                {subscriptions.extension_options.public_offers.length > 0 && (
                  <h2>Standaard abonnementen</h2>
                )}
                <div>
                  {subscriptions.extension_options.public_offers.map(
                    (offer => (<RenewSubscriptionCard offer={offer} />)),
                  )}
                </div>
                <br /><br /><br />
              </>
            )}


            {!loading && subscriptions && subscriptions.upcoming && (
              <h2 className="mb-2">Toekomstig abonnement</h2>
            )}
            {!loading && subscriptions.upcoming
              && (
                <><SubscriptionCard
                  subscription={subscriptions.upcoming}
                  type="upcoming"
                />
                  <br /></>
              )}


            <h2 className="mb-2">Huidig abonnement</h2>
            {!loading && !subscriptions.current && (
              <strong>Je hebt momenteel geen actief abonnement</strong>
            )}

            {!loading && subscriptions.current
              && (
                <SubscriptionCard
                  subscription={subscriptions.current}
                  type="current"
                />
              )}

            {!loading && subscriptions.bonus && (
              <><br /><br /><h2 className="mb-2">Vrije vouchers</h2>
                <Row className="subscriptions__bonusrights">
                  {!loading && subscriptions.bonus
                    && subscriptions.bonus.map(bonusrights => (
                      <>
                        <BonusRightsCard
                          bonusrights={bonusrights}
                          key={bonusrights.id}
                        />
                      </>
                    ))}
                </Row>
              </>
            )}


            {!loading && subscriptions.historic
              && (
                <><br /><br /><h2 className="mb-2">Vorige abonnementen</h2> </>
              )}
            <div className="subscriptions__historic">
              {!loading && subscriptions.historic
                && subscriptions.historic.map(subscription => (
                  <SubscriptionCard
                    subscription={subscription}
                    type="historic"
                    key={subscription.id}
                  />
                ))}
            </div>
          </div>

        </Grid>
      </section>
    );
  }
}

export default SubscriptionOverview;
