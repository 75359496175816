import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Col, Grid, Row } from 'react-bootstrap';
import axios from 'axios';
import classNames from 'classnames';
import ReservationWizardAutoComplete from '../../ReservationWizardAutoComplete/ReservationWizardAutoComplete';
import ReservationWizardPlayersOverview from '../../ReservationWizardPlayersOverview/ReservationWizardPlayersOverview';
import ReservationWizardButtons from '../../ReservationWizardButtons/ReservationWizardButtons';
import errorIcon from '../../ReservationWizardAutoComplete/error-icon.svg';
import iconClose from '../../ReservationWizard/ReservationCloseButton/icon-close.svg';

const ExistingReservationUserStep = () => {
  const { reservationId } = useParams();
  const reservation = useSelector(state => state.reservation);
  const [maxPlayers, setMaxPlayers] = useState(0);


  const fetchReservation = async () => {
    const request = await axios.get(`${process.env.CONF_API_URL}/api/reservations/${reservationId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });

    setMaxPlayers(request.data.data.free_slots);
  };

  useEffect(() => { fetchReservation(); }, [reservationId]);

  return (
    <section className="wizardStep wizardStepBack">

      <Grid className=" wizardStep__section">
        <NavLink to="/reservations" className="cancel-reservation">
          <span>terug naar reservering</span>
          <img src={iconClose} alt="X" />
        </NavLink>

        <div className="wizardStep__section__wrapper">
          <h2>Medespelers</h2>
          <h1>Voeg een medespeler toe</h1>

          {reservation.players.length > maxPlayers ? (
            <Row>
              <Col xs={12} sm={10}>
                <div className={classNames('wizard-autocomplete-warning no-arrow', { active: true })}>
                  <img src={errorIcon} alt="" />
                  <span className="wizard-autocomplete-warning-message">
                  Het maximaal aantal spelers voor deze boeking is bereikt.
                  </span>
                </div>
              </Col>
            </Row>
          )
            : (<>

              <Row className="margin-bottom">
                <Col xs={12} sm={6}>
                    Voeg toe op e-mailadres
                </Col>
              </Row>
              <Row>
                <ReservationWizardAutoComplete reservationId={reservationId} />
              </Row>
              </>)}

          <Row>
            <Col xs={12}>
              <ReservationWizardPlayersOverview hideSelf />
            </Col>
          </Row>


          <ReservationWizardButtons
            backHidden
            nextDisabled={reservation.players.length < 1}
            nextPath={`/reservations/${reservationId}/confirm`}
            nextText="verder"
          />
        </div>
      </Grid>
    </section>);
};
export default (ExistingReservationUserStep);
