import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { Col, Grid, Row } from 'react-bootstrap';
import axios from 'axios';
import classNames from 'classnames';
import ReservationWizardAutoComplete from '../../ReservationWizardAutoComplete/ReservationWizardAutoComplete';
import ReservationWizardPlayersOverview from '../../ReservationWizardPlayersOverview/ReservationWizardPlayersOverview';
import ReservationWizardButtons from '../../ReservationWizardButtons/ReservationWizardButtons';
import addIcon from '../../ReservationWizardAutoComplete/add-icon.svg';
import errorIcon from '../../ReservationWizardAutoComplete/error-icon.svg';
import iconClose from '../../ReservationWizard/ReservationCloseButton/icon-close.svg';
import Loader from '../../Loader/Loader';
import { resetReservation } from '../../../redux/actions/ReservationActions';


const renderPlayers = players => (
  players.map(player => (
    `<strong>${player.first_name} ${player.prefix || ''} ${player.last_name}</strong><br />`
  )).join('')
);


const ExistingReservationConfirmStep = () => {
  const { reservationId } = useParams();
  const reservation = useSelector(state => state.reservation);
  const [currentPlayers, setCurrentPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [addPlayerError, setAddPlayerError] = useState('');
  const dispatch = useDispatch();


  const fetchReservation = async () => {
    setLoading(true);
    const request = await axios.get(`${process.env.CONF_API_URL}/api/reservations/${reservationId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    setLoading(false);
    setCurrentPlayers(request.data.data.players.filter(p => p.status !== 'geannuleerd'));
  };

  const { email: currentUserEmail } = localStorage;
  const filteredPlayers = reservation.players.filter(player => player.email !== currentUserEmail);

  const addPlayers = () => {
    setAddLoading(true);
    axios.post(`${process.env.CONF_API_URL}/api/reservations/${reservationId}/player/add`,
      { players: reservation.players.map(player => player.email) },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).catch((error) => {
      if (error.response && error.response.data && error.response.data.error) {
        setAddPlayerError(error.response.data.error);
      } else {
        setAddPlayerError('Onbekende fout');
      }
      setAddLoading(false);
    }).then((req) => {
      if (req && req.data && req.data.success) {
        setAddLoading(false);
      } else if (req && req.data && !req.data.success) {
        setAddPlayerError('Onbekende fout');
      }
    });
  };


  useEffect(() => {
    fetchReservation(); return () => {
      dispatch(resetReservation());
    };
  }, [reservationId]);
  useEffect(() => { addPlayers(); }, []);

  return (
    <section className="wizardStep wizardStepBack">

      <Grid className=" wizardStep__section">
        <NavLink to="/reservations" className="cancel-reservation">
          <span>terug naar reservering</span>
          <img src={iconClose} alt="X" />
        </NavLink>

        <div className="wizardStep__section__wrapper">
          <h2>Medespelers</h2>
          <h1>Medespeler toevoegen</h1>


          {!addLoading && !addPlayerError && (
            <Row>
              <Col xs={12} sm={10} componentClass="mb-2">
                <div className={classNames('wizard-autocomplete-warning no-arrow', { active: true })}>
                  <img src={addIcon} alt="" />
                  <span className="wizard-autocomplete-warning-message">
                    {`${filteredPlayers.length} spelers toegevoegd!`}
                  </span>
                </div>
              </Col>
            </Row>
          )}

          {!addLoading && addPlayerError && (
            <Row>
              <Col xs={12} sm={10} componentClass="mb-2">
                <div className={classNames('wizard-autocomplete-warning no-arrow', { active: true })}>
                  <img src={errorIcon} alt="" />
                  <span className="wizard-autocomplete-warning-message">
                    {`Er is een fout opgetreden bij het toevoegen: ${addPlayerError}`}
                  </span>
                </div>
              </Col>
            </Row>
          )}


          {(loading || addLoading) && (<Loader size={36} color="white" />) }


          {!loading && !addLoading && !addPlayerError && (
            <Row>
              <br />

              {/* <Col xs={12} sm={6}> */}
              <span className="overview-title mt-2">Alle spelers:</span>
              <span
                className="overview-value"
                dangerouslySetInnerHTML={{ __html: renderPlayers([...currentPlayers, ...filteredPlayers]) }}
              />
              {/* </Col> */}

            </Row>
          )}

          <ReservationWizardButtons
            backHidden
            nextPath="/reservations"
            nextText="terug naar reserveringen"
          />
        </div>
      </Grid>
    </section>);
};
export default (ExistingReservationConfirmStep);
